import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import * as $ from 'jquery';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './Pages/home/home.component';
import { ProductsComponent } from './Pages/products/products.component';
import { CustomersComponent } from './Pages/customers/customers.component';
import { BlogsComponent } from './Pages/blogs/blogs.component';
import { DownloadsComponent } from './Pages/downloads/downloads.component';
import { ContactComponent } from './Pages/contact/contact.component';
import { HeaderComponent } from './Components/header/header.component';
import { FooterComponent } from './Components/footer/footer.component';
import { NavigationComponent } from './Components/navigation/navigation.component';
import { NoPageFoundComponent } from './Pages/no-page-found/no-page-found.component';
import { ProductCarouselComponent } from './Components/product-carousel/product-carousel.component';
import { AboutusComponent } from './Components/aboutus/aboutus.component';
import { ProductSectionComponent } from './Components/product-section/product-section.component';
import { DownloadSectionComponent } from './Components/download-section/download-section.component';
import { FeaturesComponent } from './Components/features/features.component';
import { TestimonialsComponent } from './Components/testimonials/testimonials.component';
import { ClientsComponent } from './Components/clients/clients.component';
import { CustomProductsComponent } from './Components/custom-products/custom-products.component';
import { ProductListComponent } from './Components/product-list/product-list.component';
import { ContactAddressComponent } from './Components/contact-address/contact-address.component';
import { ContactEnquiryComponent } from './Components/contact-enquiry/contact-enquiry.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
// import { GoogleMapsModule } from "@angular/google-maps";
// import { GoogleMapComponent } from './Components/google-map/google-map.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductsComponent,
    CustomersComponent,
    BlogsComponent,
    DownloadsComponent,
    ContactComponent,
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    NoPageFoundComponent,
    ProductCarouselComponent,
    AboutusComponent,
    ProductSectionComponent,
    DownloadSectionComponent,
    FeaturesComponent,
    TestimonialsComponent,
    ClientsComponent,
    CustomProductsComponent,
    ProductListComponent,
    ContactAddressComponent,
    ContactEnquiryComponent,
    // GoogleMapComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    // GoogleMapsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
