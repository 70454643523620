import { Component, OnInit } from '@angular/core';
import { ClientsComponent } from '../../Components/clients/clients.component';
import { TestimonialsComponent } from '../../Components/testimonials/testimonials.component';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
