import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  thisYear = new Date().getFullYear();

  constructor() { }

  onScrollTop(event: any) {
    // window.scroll(0,0);
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  }

  ngOnInit(): void {
  }

}
