<div class="contact-enquiry-container">
  <h1 class="text-center">Send Enquiry</h1>
  <div class="form-content">
    <div class="alert {{msgType}}" *ngIf="showMsg">{{enquiryFormMessage}}</div>
    <form>
        <div class="form-group">
          <input type="text" class="form-control" #name placeholder="Name" />
        </div>
        <div class="form-group">
          <input type="text" class="form-control" #mobile placeholder="Mobile" minlength=10 maxlength=10 />
        </div>
        <div class="form-group">
          <input type="email" class="form-control" #email placeholder="Email" />
        </div>
        <div class="form-group">
          <input type="text" class="form-control" #company placeholder="Company" />
        </div>
        <div class="form-group">
          <textarea class="form-control" #message placeholder="Message"></textarea>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-primary" (click)="sendEnquiryMail()">Send Message</button>
        </div>
    </form>
  </div>
</div>
