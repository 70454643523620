import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductSectionComponent } from '../../Components/product-section/product-section.component';
import { CustomProductsComponent } from '../../Components/custom-products/custom-products.component';
import { ProductListComponent } from '../../Components/product-list/product-list.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  viewProductList: boolean = false;
  productName: string = "";

  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  onViewAllClick(selectedProductName: string) {
    this.productName = selectedProductName;
    this.viewProductList = true;
  }

  onBackToProductHomeClick() {
    this.productName = "";
    this.viewProductList = false;
    this.router.navigate([], {
      queryParams: {
        'id': null
      },
      queryParamsHandling: 'merge'
    })
  }

  ngOnInit(): void {
    var idParam = this.activatedRoute.snapshot.paramMap.get('id');
    if(idParam) {
      console.log("selectedProductName - ", idParam)
      this.productName = idParam;
      this.viewProductList = true;
    }
  }

}
