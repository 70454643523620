<div class="container">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
            <app-contact-address></app-contact-address>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
            <app-contact-enquiry></app-contact-enquiry>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!-- <div class="map-content">
                <app-google-map></app-google-map>
            </div> -->
        </div>
    </div>
</div>