<footer class="footer-content">
  <div class="row">
    <div class="col-6 footer-left-content">
      <div>
        © {{thisYear}} <span class="brand-name">Mechcart</span>
      </div>
    </div>
    <div class="col-6 footer-right-content">
      <div class="socialIcons">
        <div class="youtube-icon">
          <a href="https://www.youtube.com/channel/UCgex8-I9N4KqEg35p0HIOhA" target="_blank">
            <i class="fab fa-youtube-square fa-2x"></i>
          </a>
        </div>
      </div>
      <div class="socialIcons">
        <div class="facebook-icon">
          <a href="https://www.facebook.com/MechcartSupport" target="_blank">
            <i class="fab fa-facebook-square fa-2x"></i>
          </a>
        </div>
      </div>
      <div class="verifiedDealerIcon">
        <a href="https://www.indiamart.com/mechcart/" target="_blank"
          ><img
            class="rounded"
            src="assets/images/site-imgs/imlogo.png"
        /></a>
      </div>
      <div><a href="javascript:void(0)" (click)="onScrollTop($event)"><i class="fas fa-chevron-circle-up fa-2x"></i></a></div>
    </div>
  </div>
</footer>
<!-- The core Firebase JS SDK is always required and must be listed first -->
<script src="https://www.gstatic.com/firebasejs/8.2.3/firebase-app.js"></script>

<!-- TODO: Add SDKs for Firebase products that you want to use
     https://firebase.google.com/docs/web/setup#available-libraries -->
<script src="https://www.gstatic.com/firebasejs/8.2.3/firebase-analytics.js"></script>

<script>
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  var firebaseConfig = {
    apiKey: "AIzaSyDr1I16JCnksQwyWecz2bQhtqGYzGnBj4M",
    authDomain: "mechcart-92887.firebaseapp.com",
    projectId: "mechcart-92887",
    storageBucket: "mechcart-92887.appspot.com",
    messagingSenderId: "953473228780",
    appId: "1:953473228780:web:9227dc99a82bf14537eac9",
    measurementId: "G-GDWF3PFD8H"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
</script>
