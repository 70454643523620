import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  slides = [
    "2.png", "5.png", "6.png", "4.png", "7.png", "3.png", "1.png"
  ];

  slideConfig = {
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    "slidesToShow": 6,
    "slidesToScroll": 1,
    "infinite": true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  };

  constructor() { }

  slickInit(event: any) {
    console.log('slick initialized');
  }

  ngOnInit(): void {
  }

}
