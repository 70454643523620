<div class="testmonial-container bg-light">
  <h3 class="text-center">Voice of Customer - Testimonials</h3>
  <div class="row">
    <div class="col-md-12">
      <div
        id="carouselClientSatisfaction"
        class="carousel slide"
        data-ride="carousel"
      >
        <!-- <ol class="carousel-indicators">
          <li
            data-target="#carouselClientSatisfaction"
            data-slide-to="0"
            class="active"
          ></li>
          <li data-target="#carouselClientSatisfaction" data-slide-to="1"></li>
          <li data-target="#carouselClientSatisfaction" data-slide-to="2"></li>
        </ol> -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="single-box">
                  <div class="img-text">
                    <h5>Solution Mindset</h5>
                    <p>
                      We approached Mechcart to get the fasteners for our new
                      imported product line, even though the assembly asks for a
                      special fastener, Mechcart has handled the challenges with
                      an out of box solution. Which made us to use standard
                      supplies without diluting the scope.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="single-box">
                  <div class="img-text">
                    <h5>Commitment to Delivery</h5>
                    <p>
                      We had a supply issue during the crisis’s situation, and
                      we approached MECHCART; team has made the timely support
                      and fulfilled the demand with the quality product. We have
                      not seen any supply issues after we engaged them.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="single-box">
                  <div class="img-text">
                    <h5>Quality Product in Competitive Price</h5>
                    <p>
                      We are so glad to have business relationship with MECHCART.
                      We have been impressed with their quality of their product and timely delivery,
                      that has helped and also enabled business solutions and new customers in industry.
                      Your timely technical solution and cost competitive services are truly Amazing.
                      Unique Solution! Great Service!! Amazing Partner!!!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#carouselClientSatisfaction"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#carouselClientSatisfaction"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</div>
