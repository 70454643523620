<div class="contact-address-container">
  <h1 class="text-center">Reach Us</h1>
  <div class="row justify-content-center">
    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
      <div class="contact-phone">
        <div class="phone-icon">
          <i class="fa fa-phone-alt"></i>
        </div>
        <div class="contact-phone-text">
          <h3>Phone</h3>
          <div class="phone-number">+91 - 95-0002-4755</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
      <div class="contact-email">
        <div class="email-icon">
          <i class="fas fa-envelope"></i>
        </div>
        <div class="contact-email-text">
          <h3>Email</h3>
          <div class="email-address">support@mechcart.com</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
      <div class="contact-address">
        <div class="address-icon">
          <i class="fas fa-map-marker-alt"></i>
        </div>
        <div class="contact-address-text">
          <h3>Address</h3>
          <div class="address-lines">
            <div class="line1">Plot No 14C</div>
            <div class="line1">1st Main Road</div>
            <div class="line2">VGP Dr. Vimala Nagar</div>
            <div class="line3">Medavakkam</div>
            <div class="line4">Chennai - 100</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
