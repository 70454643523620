<div class="aboutus-container">
  <div class="section-heading text-center">
    <span>About</span>
    <span class="ml-2 font-weight-bold">Mechcart</span>
  </div>
  <div class="aboutus-message">
    <p>
      Mechcart is a fasteners and industrial hub formed by a Professionals to
      facilitate and fulfil the industrial inventor´s and mechanical Engr’s
      needs. With an extensive industrial and product knowledge; we as MECHCART
      providing solution on fasteners selection and assembly challenges. With
      the support of extensive vendor base network, we are able to fulfill wider
      range of customers requirements in timely manner.
    </p>
    <p>
      We distribute variety of fasteners such as Screw, Bolt, Nut, Washer,
      Retainer ring, Dowel Pins and Split Pins in various configurations. we
      strongly believe that the customer retention only possible with the
      quality supply; and we will work for it. We have fulfilled our customer
      needs in wider product areas as Electromechanical Products, Industrial Hydraulics, Heavy Machineries,
      Fluid Systems, Medical Devices, Food & Chemical Industries and Consumer electronics.
    </p>
  </div>
</div>
