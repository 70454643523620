<div class="downloads-container">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <b
        ><a class="nav-link active" data-toggle="tab" href="#menu1"
          >Standard References</a
        ></b
      >
    </li>
  </ul>
  <div class="tab-content">
    <div id="menu1" class="container tab-pane active">
      <br />
      <!-- <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Bolt / Screw
          <span class="badge badge-light badge-pill">5</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Nut
          <span class="badge badge-light badge-pill">3</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Circlip / Retainer Ring
          <span class="badge badge-light badge-pill">5</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Pin
          <span class="badge badge-light badge-pill">2</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
          Washer
          <span class="badge badge-light badge-pill">2</span>
        </li>
      </ul> -->
      <div class="list-group" *ngFor="let downloadItem of downloadItems">
        <div class="list-group-item">
          <div class="d-flex w-100 justify-content-between">
            <h6 class="mb-1">{{ downloadItem.title }}</h6>
          </div>
        </div>
        <div
          class="list-content"
          *ngIf="downloadItem.listItems && downloadItem.listItems.length > 0"
        >
          <table class="table table-borderless table-sm mx-2">
            <tbody>
              <tr *ngFor="let listItem of downloadItem.listItems">
                <td>
                  <a href="{{ listItem.downloadLink }}" target="_blank">{{ listItem.name }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
