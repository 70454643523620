<div class="features-container">
  <h3 class="text-center">We Do</h3>
  <div class="row nopadding">
    <div class="col-12 col-sm-12 col-md-4 col-lg-4">
      <div class="card">
        <div class="card-body text-center">
          <div class="card-title">
            <span><i class="fas fa-lightbulb pr-2"></i>Fastner Solutions</span>
          </div>
          <p class="card-text">
            We support our customers;<br/> on securing their design<br/> with right
            fasteners.
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-4 col-lg-4">
      <div class="card">
        <div class="card-body text-center">
          <div class="card-title">
            <span><i class="fas fa-user-shield pr-2"></i>Our Mission</span>
          </div>
          <p class="card-text">
            We Believe the Word; <br/>
            “Quality with US <br/>Customer with US” <br/>and we toil
            for it.
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-4 col-lg-4">
      <div class="card">
        <div class="card-body text-center">
          <div class="card-title">
            <span><i class="fas fa-handshake pr-2"></i>We Support</span>
          </div>
          <p class="card-text">
            We work 24/7 for our<br/> Customer’s needs.<br/> We are there to support our<br/>
            inventors all time.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
