<div class="container">
    <div *ngIf="!viewProductList">
        <div class="container">
            <app-product-section (viewAllButtonClick)="onViewAllClick($event)"></app-product-section>
            <app-custom-products></app-custom-products>
        </div>
    </div>
    <div *ngIf="viewProductList">
        <app-product-list (backButtonClick)="onBackToProductHomeClick()" [productName]="productName"></app-product-list>
    </div>
</div>