import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-address',
  templateUrl: './contact-address.component.html',
  styleUrls: ['./contact-address.component.scss']
})
export class ContactAddressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
