import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
declare var $ : any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = '::Mechcart::Industrial Supplier';

  @ViewChild("name") nameElem: ElementRef;
  @ViewChild("mobile") mobileElem: ElementRef;
  @ViewChild("email") emailElem: ElementRef;
  @ViewChild("company") companyElem: ElementRef;
  @ViewChild("message") messageElem: ElementRef;

  enquiryFormMessage: string;
  showMsg: boolean = false;
  msgType: string;

  sendEnquiryMail() {
    var sendEmailFlag = false;
    this.nameElem.nativeElement.style.border = "1px solid #ced4da";
    this.mobileElem.nativeElement.style.border = "1px solid #ced4da";
    this.emailElem.nativeElement.style.border = "1px solid #ced4da";
    this.companyElem.nativeElement.style.border = "1px solid #ced4da";
    this.messageElem.nativeElement.style.border = "1px solid #ced4da";

    var name = this.nameElem.nativeElement.value;
    var mobile = this.mobileElem.nativeElement.value;
    var email = this.emailElem.nativeElement.value;
    var company = this.companyElem.nativeElement.value;
    var message = this.messageElem.nativeElement.value;

    this.enquiryFormMessage = "";
    this.showMsg = false;

    if(name != "" && mobile != "" && email != "" && company != "" && message != "") {
      sendEmailFlag = true;
      var emailRegex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

      if(name.length < 3 || name == "test") {
        sendEmailFlag = false;
        this.msgType = "alert-danger";
        this.showMsg = true;
        this.enquiryFormMessage = "Please enter valid Name (Min 3 Chars)";
        this.nameElem.nativeElement.style.border = "1px solid red";
      } else if(mobile.length < 10 || isNaN(mobile)) {
        sendEmailFlag = false;
        this.msgType = "alert-danger";
        this.showMsg = true;
        this.enquiryFormMessage = "Please enter valid Mobile (Min 10 Chars)";
        this.mobileElem.nativeElement.style.border = "1px solid red";
      } else if(email != "" && !emailRegex.test(email)) {
        sendEmailFlag = false;
        this.msgType = "alert-danger";
        this.showMsg = true;
        this.enquiryFormMessage = "Please enter valid Email";
        this.emailElem.nativeElement.style.border = "1px solid red";
      } else if(company.length < 3 || company == "test") {
        sendEmailFlag = false;
        this.msgType = "alert-danger";
        this.showMsg = true;
        this.enquiryFormMessage = "Please enter valid Company (Min 3 Chars)";
        this.companyElem.nativeElement.style.border = "1px solid red";
      } else if(message.length < 5) {
        sendEmailFlag = false;
        this.msgType = "alert-danger";
        this.showMsg = true;
        this.enquiryFormMessage = "Please enter valid Message (Min 5 Chars)";
        this.messageElem.nativeElement.style.border = "1px solid red";
      }
    } else {
        sendEmailFlag = false;
        this.msgType = "alert-danger";
        this.showMsg = true;
        this.enquiryFormMessage = "Please fill out fields correctly";
    }

    if(sendEmailFlag) {
      const templateParams = {
          name: name,
          phone: mobile,
          email: email,
          company: company,
          message: message
      };

      emailjs.send('service_hm2aom6','template_0fsz1qi', templateParams, 'user_2vqGid5Y2lKFY9c9jy2h1').then(
      (result: EmailJSResponseStatus) => {
        console.log(result.text);
        this.nameElem.nativeElement.value = "";
        this.mobileElem.nativeElement.value = "";
        this.emailElem.nativeElement.value = "";
        this.companyElem.nativeElement.value = "";
        this.messageElem.nativeElement.value = "";
        this.msgType = "alert-success";
        this.showMsg = true;
        this.enquiryFormMessage = "Thanks for the Info, We will contact you shortly";
      }, (error) => {
        console.log(error.text);
        this.msgType = "alert-danger";
        this.showMsg = true;
        this.enquiryFormMessage = "There is some problem in enquiry submission";
      });
    }
    
  }

  ngOnInit(): void {
    $(function () {
      $('#whatsappSection').floatingWhatsApp({
        headerTitle: 'Mechcart Support',
        phone: '919500024755',
        popupMessage: 'Hello, how can we help you?',
        showPopup: true
      });
    });
  }
}
