<header class="header" id="header">
  <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 50 1440 220">
    <path fill="#ab000f" fill-opacity="1" d="M0,64L48,58.7C96,53,192,43,288,69.3C384,96,480,160,576,165.3C672,171,768,117,864,101.3C960,85,1056,107,1152,144C1248,181,1344,235,1392,261.3L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
  </svg> -->

  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" height="100" width="100%" viewBox="0 0 90 40" preserveAspectRatio="none">
    <path d="M0 5 H5 C25 5 25 20 45 20 S65 5 85 5 H90 V-5 H0z" fill="#e8e8e8" stroke="transparent"/>
  </svg>

  <div class="row nomargin">
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 brandSection">
      <div class="brandImage">
        <a routerLink="/home" routerLinkActive="active">
          <img
            src="../../../assets/images/mechcart-logo.png"
            alt=""
            class="img-responsive"
          />
        </a>
      </div>
      <div class="brand">
        <div class="brandName">Mechcart</div>
        <div class="brand-info">
          <div class="brand-address">
            <div><i class="fas fa-map-marker-alt pr-2"></i></div>
            <div class="addressLines">
              <div>Medavakkam, Chennai</div>
            </div>
          </div>
          <div class="brand-gst">
            <i class="fas fa-money-bill-wave-alt pr-2"></i>
            <strong class="pr-2">GST:</strong>
            <span>33BKSPS8196N1ZW</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 additionalInfoSection pr-0">
      <div class="enquirySection">
        <div class="enquiryMail">
          <div
            class="enquiryContent"
            data-toggle="modal"
            data-target="#enquiryModal"
          >
            <i class="fa fa-envelope pr-2"></i>
            <span>Send Inquiry</span>
          </div>
        </div>
        <div class="enquiryContact">
          <div class="enquiryContent">
            <i class="fa fa-phone-alt pr-2"></i>
            <span>+91-95-0002-4755</span>
          </div>
        </div>
        <div class="enquiryMail">
          <div class="enquiryContent">
            <i class="fa fa-at pr-2"></i>
            <span>support@mechcart.com</span>
          </div>
        </div>
      </div>
      <div class="header-icons">
        <div class="socialIcons">
          <div class="youtube-icon">
            <a href="https://www.youtube.com/channel/UCgex8-I9N4KqEg35p0HIOhA" target="_blank">
              <i class="fab fa-youtube-square fa-2x"></i>
            </a>
          </div>
          <div class="facebook-icon">
            <a href="https://www.facebook.com/MechcartSupport" target="_blank">
              <i class="fab fa-facebook-square fa-2x"></i>
            </a>
          </div>
        </div>
        <div class="verifiedDealerIcon">
          <a href="https://www.indiamart.com/mechcart/" target="_blank"
            ><img
              class="rounded"
              src="assets/images/site-imgs/imlogo.png"
          /></a>
        </div>
      </div>
    </div>
  </div>
</header>
<app-navigation></app-navigation>
