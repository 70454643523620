<div class="product-list-container">
  <div class="product-list-header">
    <div class="back-button">
      <button class="btn btn-primary" (click)="onBackButtonClick()">
        <i class="fa fa-chevron-left pr-2"></i>Back
      </button>
    </div>
    <div class="product-name">
      <h4 class="text-center">{{ productInfo.title }}</h4>
    </div>
  </div>
  <div class="product-description" *ngIf="productInfo.description != ''">
    <p>
      {{ productInfo.description }}
    </p>
  </div>
  <div
    class="product-list"
    *ngIf="
      productInfo && productInfo.products && productInfo.products.length > 0
    "
  >
    <div class="product-detail" *ngFor="let item of productInfo.products">
      <div class="card">
        <div class="row">
          <div class="col-12 col-sm-3 col-md-3 product-img-holder">
            <div class="product-img-area">
              <img
                src="assets/images/products/{{ item.productImage }}"
                class="img-fluid rounded"
              />
            </div>
          </div>
          <div class="col-12 col-sm-9 col-md-9">
            <div class="product-info">
              <div class="heading">
                <div class="row">
                  <div class="col-9 col-sm-9 col-md-9">
                    <div class="product-title">{{ item.productTitle }}</div>
                    <!-- <div class="product-price" *ngIf="item.productPrice">
                      Price: {{ item.productPrice }} INR
                    </div> -->
                  </div>
                  <div class="col-3 col-sm-3 col-md-3 btn-section">
                    <div class="send-inquiry-button">
                      <button
                        class="btn btn-primary btn-sm"
                        data-toggle="modal"
                        data-target="#enquiryModal"
                      >
                        Send Inquiry
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="product-desc">
                <div
                  class="row product-desc-detail"
                  *ngFor="let info of item.productInfo"
                >
                  <div class="col-4 col-sm-4 col-md-4 title-col">
                    <span class="title">{{ info.infoTitle }}:</span>
                  </div>
                  <div class="col-8 col-sm-8 col-md-8 content-col my-auto">
                    <div class="text">{{ info.infoText1 }}</div>
                    <div class="text" *ngIf="info.infoText2">
                      {{ info.infoText2 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="no-data"
    *ngIf="
      !(productInfo && productInfo.products && productInfo.products.length > 0)
    "
  >
    <h4 class="text-center">Products yet to be added</h4>
  </div>
</div>
