import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download-section',
  templateUrl: './download-section.component.html',
  styleUrls: ['./download-section.component.scss'],
})
export class DownloadSectionComponent implements OnInit {
  downloadItems: any;

  constructor() {}

  // downloadFile(fileName: string) {
  //   console.log(" comes in filename - ", fileName)
  //   var url = "assets/downloads/"+ fileName +".pdf";
  //   var link = document.createElement('a');
  //   link.href = url;
  //   link.download = fileName+'.pdf';
  //   link.dispatchEvent(new MouseEvent('click'));
  // }

  ngOnInit(): void {
    this.downloadItems = [
      {
        title: 'Bolt / Screw',
        listItems: [
          {
            name: 'ISO 724 - Metric Screw Thread',
            downloadLink:
              'assets/downloads/Bolt_Screw/ISO 724 - Metric Screw Thread.pdf',
          },
          {
            name: 'ASME B18.2.8 - Metric Holes Clearance',
            downloadLink:
              'assets/downloads/Bolt_Screw/ASME B18.2.8 - Metric Holes Clearance.pdf',
          },
          {
            name: 'IS 2269 - Socket Head Cap Screw',
            downloadLink:
              'assets/downloads/Bolt_Screw/IS 2269- Socket Head Cap Screw.pdf',
          },
          {
            name: 'IS 6094 - Hex Socket Set Screw',
            downloadLink:
              'assets/downloads/Bolt_Screw/IS 6094- Hex Socket Set Screw.pdf',
          },
          {
            name: 'IS 7173_ISO 1478 - Self Tapping Screw',
            downloadLink:
              'assets/downloads/Bolt_Screw/IS 7173_ISO 1478 - Self Tapping screw.pdf',
          },
        ],
      },
      {
        title: 'Nut',
        listItems: [
          {
            name: 'DIN 928 - SQR Weld Nut',
            downloadLink: 'assets/downloads/Nut/DIN 928 - SQR Weld Nut.pdf',
          },
          {
            name: 'DIN 929 - HEX Weld Nut',
            downloadLink: 'assets/downloads/Nut/DIN 929- HEX Weld Nut.pdf',
          },
          {
            name: 'DIN 985_DIN982 - Nyloc Nut',
            downloadLink: 'assets/downloads/Nut/DIN 985_DIN982 - Nyloc Nut.pdf',
          },
        ],
      },
      {
        title: 'Circlip / Retainer Ring',
        listItems: [
          {
            name: 'DIN 471 - External Circlip',
            downloadLink:
              'assets/downloads/Circlip_RetainerRIng/DIN 471- External Circlip.pdf',
          },
          {
            name: 'DIN 471 - Heavy, External Circlip',
            downloadLink:
              'assets/downloads/Circlip_RetainerRIng/DIN 471- Heavy, External Circlip.pdf',
          },
          {
            name: 'DIN 472 - Heavy, Internal Circlip',
            downloadLink:
              'assets/downloads/Circlip_RetainerRIng/DIN 472- Heavy, Internal Circlip.pdf',
          },
          {
            name: 'DIN 472 - Internal Circlip',
            downloadLink:
              'assets/downloads/Circlip_RetainerRIng/DIN 472- Internal Circlip.pdf',
          },
          {
            name: 'IS3075 - External E Clip',
            downloadLink:
              'assets/downloads/Circlip_RetainerRIng/IS3075- External E Clip.pdf',
          },
        ],
      },
      {
        title: 'Pin',
        listItems: [
          {
            name: 'ISO 1234 - Split Pin',
            downloadLink: 'assets/downloads/Pin/ISO 1234- Split Pin.pdf',
          },
          {
            name: 'ISO 8752 - Spring Dowel Pin',
            downloadLink: 'assets/downloads/Pin/ISO 8752- Spring Dowel Pin.pdf',
          },
        ],
      },
      {
        title: 'Washer',
        listItems: [
          {
            name: 'DIN 127_DIN7980 - Spring Washer',
            downloadLink:
              'assets/downloads/Washer/DIN 127_DIN7980 - Spring Washer.pdf',
          },
          {
            name: 'DIN 6797- Toother Lock Washer',
            downloadLink:
              'assets/downloads/Washer/DIN 6797- Toother Lock Washer.pdf',
          },
        ],
      },
    ];
  }
}
