<div class="clients-container">
  <h3 class="text-center">Our Customers</h3>
  <ngx-slick-carousel
    class="carousel"
    #slickModal="slick-carousel"
    [config]="slideConfig"
    (init)="slickInit($event)"
  >
    <div ngxSlickItem *ngFor="let slide of slides" class="slide">
      <div class="img-area">
        <img src="assets/images/clients/{{ slide }}" alt="" />
      </div>
    </div>
  </ngx-slick-carousel>
</div>
