<div class="container page-design nopadding bg-white">
  <app-header></app-header>
  <div class="content">
    <router-outlet></router-outlet>

    <!-- The Modal -->
    <div class="modal" id="enquiryModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title text-center">Mechcart</h4>
            <button type="button" class="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="alert {{msgType}}" *ngIf="showMsg">{{enquiryFormMessage}}</div>
              <div class="form-group">
                <label for="comment">
                  Describe Your Requirement:
                  <div class="label-desc">
                    Tips on getting accurate quotes. Please include product
                    name, order quantity, usage, special requests if any in your
                    inquiry.
                  </div>
                </label>
                <textarea class="form-control" rows="2" #message></textarea>
              </div>
              <div class="form-group">
                <input type="text"
                  class="form-control"
                  #name
                  placeholder="Name"
                  name="name"
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  #mobile
                  placeholder="Mobile"
                  name="mobile"
                />
              </div>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  #email
                  placeholder="Email"
                  name="email"
                />
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  #company
                  placeholder="Company"
                  name="company"
                />
              </div>
              <div class="modal-send-inquiry-button">
                <button type="button" class="btn btn-primary" (click)="sendEnquiryMail()">
                  Send Enquiry
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div id="whatsappSection"></div>
  </div>
  <app-footer></app-footer>
</div>
