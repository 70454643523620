<nav class="navbar navbar-expand-sm navbar-dark">
    <!-- <a class="navbar-brand" href="#">Mechcart</a> -->
    <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbars" aria-controls="navbars" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="navbar-collapse collapse" id="navbars">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="/home" routerLinkActive="active">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/products" routerLinkActive="active">Products</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/customers" routerLinkActive="active">Customers</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/downloads" routerLinkActive="active">Downloads</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/contact" routerLinkActive="active">Contact</a>
        </li>
        <!-- <li class="nav-item">
            <a class="nav-link" routerLink="/blog" routerLinkActive="active">Blog</a>
        </li> -->
      </ul>
    </div>
  </nav>