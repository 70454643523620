<div class="custom-product-container">
  <h3 class="text-center">Custom/Special Products</h3>
  <div class="row" id="gallery">
    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
      <div class="card" data-toggle="modal" data-target="#exampleModal">
        <div class="card-body text-center">
          <div class="img-area">
            <img
              class="img-fluid rounded"
              src="assets/images/custom_products/Custom Allenkey.png"
              data-target="#carousel-custon-product"
              data-slide-to="0"
            />
          </div>
          <div class="card-title">Custom Allenkey</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
      <div class="card" data-toggle="modal" data-target="#exampleModal">
        <div class="card-body text-center">
          <div class="img-area">
            <img
              class="img-fluid rounded"
              src="assets/images/custom_products/Dowel Pin.png"
              data-target="#carousel-custon-product"
              data-slide-to="0"
            />
          </div>
          <div class="card-title">Dowel Pin</div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
      <div class="card" data-toggle="modal" data-target="#exampleModal">
        <div class="card-body text-center">
          <div class="img-area">
            <img
              class="img-fluid rounded"
              src="assets/images/custom_products/Micro Pin.png"
              data-target="#carousel-custon-product"
              data-slide-to="0"
            />
          </div>
          <div class="card-title">Micro Pin</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h6>Custom / Special Products</h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div
          id="carousel-custon-product"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="d-block w-100"
                src="assets/images/custom_products/Custom Allenkey.png"
              />
              <h3 class="text-center">Custom Allenkey</h3>
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src="assets/images/custom_products/Dowel Pin.png" />
              <h3 class="text-center">Dowel Pin</h3>
            </div>
            <div class="carousel-item">
              <img class="d-block w-100" src="assets/images/custom_products/Micro Pin.png" />
              <h3 class="text-center">Micro Pin</h3>
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carousel-custon-product"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carousel-custon-product"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
