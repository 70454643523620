import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  @Input() productItems: any[] = [];
  @Input() productName: string;

  @Output("backButtonClick") backButtonClick: EventEmitter<any> = new EventEmitter();


  productInfo: any;

  productDataSet = {
    "Bolts" : {
      "title" : "Bolts",
      "description": "",
      "products" : [
        {
          "productImage": "Bolts/Socket Head.png",
          "productTitle": "Socket Head Bolt",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M2 - M42",
              "infoText2" : "Length 3mm - 300mm"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "3/16\" - 1\"",
              "infoText2" : "Length: 3/8\" - 10\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 4.6, 8.8, 10.9, 12.9, SS202, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "TVS, UNBRAKO, ATUL, APL, RAAJ and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Hot Dip Galvanized, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Bolts/Hex Head Bolt.png",
          "productTitle": "Hex Head Bolt",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M3 - M48",
              "infoText2" : "Length 10mm - 300mm"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "3/16\" - 1\"",
              "infoText2" : "Length 3/8\" - 10\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 4.8, 8.8, 10.9, SS202, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "TVS, UNBRAKO, ATUL, APL, RAAJ and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish,Hot Dip Galvanized, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Bolts/Flange Hex Bolt.png",
          "productTitle": "Flange Hex Head Bolt",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M3 - M48",
              "infoText2" : "Length 10mm - 300mm"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "3/16\" - 1\"",
              "infoText2" : "Length 3/8\" - 10\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 4.8, 12.9, SS202, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "TVS, UNBRAKO, ATUL, APL, RAAJ and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish,Hot Dip Galvanized, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Bolts/Eye Bolt.png",
          "productTitle": "Eye Bolt",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M5 - M36",
              "infoText2" : "50mm - 160mm",
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "3/16\" - 1\"",
              "infoText2" : "Length 3/8\" - 10\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 4.8, 5.6, Stainless Steel",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Zinc Coated",
            }
          ]
        }
      ]
    },
    "Screws" : {
      "title" : "Screws",
      "description": "",
      "products" : [
        {
          "productImage": "Screws/Set Screw.png",
          "productTitle": "Set Screw",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M2 - M36",
              "infoText2" : "Length: 3mm - 200mm",
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "3/16\" - 3/8\"",
              "infoText2" : "Length 3/8\" - 3\""
            },
            {
              "infoTitle" : "Drive",
              "infoText1" : "Slotted, Allen",
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 4.8, 12.9, SS202, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "TVS, UNBRAKO, ATUL, APL, RAAJ and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Screws/Machine Screw.png",
          "productTitle": "Machine Screw",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M2 - M10",
              "infoText2" : "Length: 3mm - 100mm",
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "3/16\" - 3/8\"",
              "infoText2" : "Length 3/8\" - 3\""
            },
            {
              "infoTitle" : "Drive",
              "infoText1" : "Slotted, Philip Drive, Combo Drive",
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 4.8, 5.6, SS202, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "APL, RAAJ and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Screws/Washer Head Screw.png",
          "productTitle": "Washer Head Screw",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M2 - M10",
              "infoText2" : "Length: 3mm - 100mm",
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "3/16\" - 3/8\"",
              "infoText2" : "Length 3/8\" - 3\""
            },
            {
              "infoTitle" : "Drive",
              "infoText1" : "Slotted, Philip Drive, Combo Drive",
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 4.8, 5.6, SS202, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "APL, RAAJ and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Screws/CSK Screw.png",
          "productTitle": "CSK Screw",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M2 - M10",
              "infoText2" : "Length: 3mm - 100mm",
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "3/16\" - 3/8\"",
              "infoText2" : "Length 3/8\" - 3\""
            },
            {
              "infoTitle" : "Drive",
              "infoText1" : "Slotted, Philip Drive, Combo Drive, Allen",
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 4.8, 5.6, SS202, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "APL and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Screws/Self Tapping Screw.png",
          "productTitle": "Self Tapping Screw",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Screw Size",
              "infoText1" : "# 0 - #16 (~1.5mm-8mm)",
              "infoText2" : "Length: 3mm - 100mm",
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "3/16\" - 3/8\"",
              "infoText2" : "Length 3/8\" - 3\""
            },
            {
              "infoTitle" : "Drive",
              "infoText1" : "Slotted, Philip Drive, Combo Drive",
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 4.8, 5.6, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "APL and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Screws/Self Drilling Screw.png",
          "productTitle": "Self Drilling Screw",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Head Type",
              "infoText1" : "Hex Head, Pan head CSK, Truss Head",
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "#4 - #12 & 1/4\"",
            },
            {
              "infoTitle" : "Drive",
              "infoText1" : "Philip Drive, Combo Drive",
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "SS400 Series",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "APL and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish",
            }
          ]
        }
      ]
    },
    "Nuts" : {
      "title" : "Nuts",
      "description": "",
      "products" : [
        {
          "productImage": "Nut/Hex Nut.png",
          "productTitle": "Hex Nut",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M3 - M72",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\" - 3\"",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 8, Grade 10",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "TVS, UNBRAKO, ATUL, APL, RAAJ and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish,Hot Dip Galvanized, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Nut/Thin Nut.png",
          "productTitle": "Thin Nut / Lock Nut",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M3 - M42",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\" - 3\"",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Available in different grades MS & SS",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "APL, RAAJ, IMPORTED and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish,Hot Dip Galvanized, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Nut/Nyloc Nut.png",
          "productTitle": "Nylock Nut",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M3 - M22",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\" - 3\"",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 8, 10, SS202, SS304, SS314",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "TVS, UNBRAKO, APL, ATULand local makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish,Hot Dip Galvanized, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Nut/Wing Nut.png",
          "productTitle": "Wing Nut",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M3 - M20",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\" - 3/4\"",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Available in different grades MS&SS",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "APL, ATUL and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish,Hot Dip Galvanized, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Nut/Eye Nut.png",
          "productTitle": "Eye Nut",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M6 - M36",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\" - 1 1/2\"",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Available in different grades",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish,Hot Dip Galvanized, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Nut/Dome Nut.png",
          "productTitle": "Domed Cap Nut",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M3 - M20",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\" - 1 1/4\"",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Available in different grades",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish,Hot Dip Galvanized, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Nut/Weld Nut.png",
          "productTitle": "Hex-Weld Nut",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M3 - M16",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\" - 1 1/4\"",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "MS, SS202, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "TVS, UNBRAKO, APL, ATUL and local makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish,Hot Dip Galvanized, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Nut/Sqr Weld Nut.png",
          "productTitle": "Square-Weld Nut",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M3 - M16",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\" - 1 1/4\"",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "MS, SS202, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "TVS, UNBRAKO, APL, ATUL and local makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish,Hot Dip Galvanized, Zinc Coated",
            }
          ]
        }
      ]
    },
    "Washer" : {
      "title" : "Washer",
      "description": "",
      "products" : [
        {
          "productImage": "Washer/Plain Washer.png",
          "productTitle": "Plain Washer",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M2 - M200",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "5/16\" - 5\"",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "MS, SS202, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "UNBRAKO, TVS, ATUL, APL and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Washer/Spring Washer.png",
          "productTitle": "Spring Washer",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M2 - M36",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "5/16\" - 5\"",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "MS, SS202, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "UNBRAKO, ATUL, APL and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Washer/Tapper Washer.png",
          "productTitle": "Tapper Washer",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M6 - M36",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "5/16\" - 5\"",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "MS",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Washer/Disc Washer.png",
          "productTitle": "Disc Washer",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M6 - M50",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "MS",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Washer/Nord Lock Washer.png",
          "productTitle": "Nord Lock Washer",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M3 - M16",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "MS",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Zinc Coated",
            }
          ]
        }
      ]
    },
    "HDG" : {
      "title" : "HDG",
      "description": "",
      "products" : [
        {
          "productImage": "HDG/HDG Bolt.png",
          "productTitle": "Hex Bolt",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M6 - M42",
              "infoText2" : "Length 45mm - 300mm"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\" - 2\"",
              "infoText2" : "Length: 1 1/2\" - 10\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 4.8, 8.8, 10.9",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Hot Dip Galvanized, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "HDG/HDG Washer.png",
          "productTitle": "Washer",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M6 - M42"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\" - 2\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 4.8, 8.8, 10.9",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Hot Dip Galvanized, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "HDG/HDG Washer.png",
          "productTitle": "Spring Washer",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M6 - M42"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\" - 2\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 4.8, 8.8, 10.9",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Hot Dip Galvanized, Zinc Coated",
            }
          ]
        },
        {
          "productImage": "HDG/HDG-Nut.png",
          "productTitle": "Nut",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M6 - M42"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\" - 2\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 4.8, 8.8, 10.9",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish, Hot Dip Galvanized, Zinc Coated",
            }
          ]
        }
      ]
    },
    "Circlip" : {
      "title" : "Circlip",
      "description": "",
      "products" : [
        {
          "productImage": "Circlip/External.png",
          "productTitle": "External Circlip",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Standard",
              "infoText1" : "3mm - 300mm"
            },
            {
              "infoTitle" : "Heavy",
              "infoText1" : "15mm - 100mm"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "MS, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish",
            }
          ]
        },
        {
          "productImage": "Circlip/Internal.png",
          "productTitle": "Internal Circlip",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Standard",
              "infoText1" : "8mm - 300mm"
            },
            {
              "infoTitle" : "Heavy",
              "infoText1" : "20mm - 100mm"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "MS, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish",
            }
          ]
        },
        {
          "productImage": "Circlip/E Clip.png",
          "productTitle": "E-Clip",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Standard",
              "infoText1" : "1mm - 42mm"
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "MS, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish",
            }
          ]
        }
      ]
    },
    "Plugs" : {
      "title" : "Plugs",
      "description": "",
      "products" : [
        {
          "productImage": "Plugs/Hex Head Plug with Oring   (VST-S).png",
          "productTitle": "Hex Head Plug with Oring (VST-S)",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Screw Size",
              "infoText1" : "5/16\" - 2 1/2\""
            },
            {
              "infoTitle" : "Drive",
              "infoText1" : "Hex Head"
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Hyloc",
            }
          ]
        },
        {
          "productImage": "Plugs/Socket head Plug with Oring   (VSTI-S).png",
          "productTitle": "Socket head Plug with Oring (VSTI-S)",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Screw Size",
              "infoText1" : "5/16\" - 2 1/2\""
            },
            {
              "infoTitle" : "Drive",
              "infoText1" : "Allen Key"
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Hyloc",
            }
          ]
        },
        {
          "productImage": "Plugs/Hex Head Plug with Oring   (VST-MO).png",
          "productTitle": "Hex Head Plug with Oring (VST-MO)",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Screw Size",
              "infoText1" : "M10 - M60"
            },
            {
              "infoTitle" : "Drive",
              "infoText1" : "Hex Head"
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Hyloc",
            }
          ]
        },
        {
          "productImage": "Plugs/Socket head Plug with Oring   (VSTI-S).png",
          "productTitle": "Socket head Plug with Oring (VSTI-MO)",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Screw Size",
              "infoText1" : "M10 - M60"
            },
            {
              "infoTitle" : "Drive",
              "infoText1" : "Allen Key"
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Hyloc",
            }
          ]
        }
      ]
    },
    "Grease Nipple" : {
      "title" : "Grease Nipple",
      "description": "",
      "products" : [
        {
          "productImage": "Grease Nipple/Standard.png",
          "productTitle": "Standard",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M5 - M16",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\", 5/16\", 3/8\", 1/2\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Steel",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural,  Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Grease Nipple/45 Elbow.png",
          "productTitle": "45 Elbow",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M5 - M16",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\", 5/16\", 3/8\", 1/2\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Steel",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural,  Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Grease Nipple/90 Elbow.png",
          "productTitle": "90 Elbow",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M5 - M16",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\", 5/16\", 3/8\", 1/2\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Steel",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural,  Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Grease Nipple/Press Fit.png",
          "productTitle": "Press Fitted",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M5 - M16",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\", 5/16\", 3/8\", 1/2\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Steel",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural,  Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Grease Nipple/Button Head.png",
          "productTitle": "Button Head",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "M5 - M16",
              "infoText2" : "STD"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/4\", 5/16\", 3/8\", 1/2\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Steel",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural,  Zinc Coated",
            }
          ]
        }
      ]
    },
    "Self Tapping Screw" : {
      "title" : "Self Tapping Screw",
      "description": "",
      "products" : [
        {
          "productImage": "Self Tapping Screw/Self Tapping Screw-AB.png",
          "productTitle": "Self Tapping Screw - AB",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Screw Size",
              "infoText1" : "#0 - #16 (~1.5mm-8mm)",
              "infoText2" : "Length: 3mm - 100mm"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "3/16\" - 3/8\"",
              "infoText2" : "Length: 3/8\" - 3\""
            },
            {
              "infoTitle" : "Drive",
              "infoText1" : "Slotted, Philip, Combo",
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 4.8, 5.6, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "APL and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural,  Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Self Tapping Screw/Self Tapping Screw B.png",
          "productTitle": "Self Tapping Screw-B",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Screw Size",
              "infoText1" : "#0 - #16 (~1.5mm-8mm)",
              "infoText2" : "Length: 3mm - 100mm"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "3/16\" - 3/8\"",
              "infoText2" : "Length: 3/8\" - 3\""
            },
            {
              "infoTitle" : "Drive",
              "infoText1" : "Slotted, Philip, Combo",
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Grade 4.8, 5.6, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "APL and Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural,  Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Self Tapping Screw/Self Drilling Screw.png",
          "productTitle": "Self Drilling Screw",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Head Type",
              "infoText1" : "Hex Head, Pan head CSK, Truss Head "
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "#4 - #12 & 1/4\""
            },
            {
              "infoTitle" : "Drive",
              "infoText1" : "Philip, Combo",
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "SS400 series",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural",
            }
          ]
        }
      ]
    },
    "Pin" : {
      "title" : "Pin",
      "description": "",
      "products" : [
        {
          "productImage": "Pin/Cotter Pin.png",
          "productTitle": "Cotter Pin",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "1mm - 6.3mm",
              "infoText2" : "Length 8mm - 150mm"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "3/64\" - 1/2\"",
              "infoText2" : "Length : 1/2\" - 7\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Mild Steel, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural,  Zinc Coated",
            }
          ]
        },
        {
          "productImage": "Pin/R Pin.png",
          "productTitle": "R Pin",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "2mm - 8mm (Wire Dia)",
              "infoText2" : "Length 5mm - 132mm"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/32\" - 5/16\" (Wire Dia)",
              "infoText2" : "Length : 1/4\" - 6\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Mild Steel, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish",
            }
          ]
        },
        {
          "productImage": "Pin/Spring Dowel pin.png",
          "productTitle": "Spring Dowel Pin",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "1mm - 36mm",
              "infoText2" : "Length 5mm - 120mm"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/32\" - 1 1/4\"",
              "infoText2" : "Length : 1/4\" - 6\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Spring Steel, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish",
            }
          ]
        },
        {
          "productImage": "Pin/Solid Dowel Pin.png",
          "productTitle": "Solid Dowel Pin",
          "productPrice": "0.00",
          "productInfo" : [
            {
              "infoTitle" : "Metric Size",
              "infoText1" : "1mm - 36mm",
              "infoText2" : "Length 5mm - 300mm"
            },
            {
              "infoTitle" : "Inch Size",
              "infoText1" : "1/32\" - 1 1/4\"",
              "infoText2" : "Length : 1/4\" - 12\""
            },
            {
              "infoTitle" : "Material Grade",
              "infoText1" : "Steel, SS304, SS316",
            },
            {
              "infoTitle" : "Make",
              "infoText1" : "Other Makes",
            },
            {
              "infoTitle" : "Color / Finish",
              "infoText1" : "Natural Finish",
            }
          ]
        }
      ]
    }
  }

  constructor() { }

  onBackButtonClick() {
    this.backButtonClick.emit();
  }

  getProductItems(name: string) {

    var keys = Object.keys(this.productDataSet)
    var values = Object.values(this.productDataSet)
    var selectedItem;

    for(let x=0; x < keys.length; x++) {
      if(keys[x] === this.productName) {
        selectedItem = values[x];
      }
    }

    return selectedItem;
  }

  ngOnInit(): void {
    if(this.productName != "") {
      this.productInfo = this.getProductItems(this.productName);
    }
  }

}
