import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogsComponent } from './Pages/blogs/blogs.component';
import { ContactComponent } from './Pages/contact/contact.component';
import { CustomersComponent } from './Pages/customers/customers.component';
import { DownloadsComponent } from './Pages/downloads/downloads.component';
import { HomeComponent } from './Pages/home/home.component';
import { NoPageFoundComponent } from './Pages/no-page-found/no-page-found.component';
import { ProductsComponent } from './Pages/products/products.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'products/:id', component: ProductsComponent },
  { path: 'customers', component: CustomersComponent },
  { path: 'downloads', component: DownloadsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'blog', component: BlogsComponent },
  { path: '**', component: NoPageFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
