import { Component, OnInit } from '@angular/core';
import { ProductCarouselComponent } from '../../Components/product-carousel/product-carousel.component';
import { AboutusComponent } from '../../Components/aboutus/aboutus.component';
import { FeaturesComponent } from '../../Components/features/features.component';
import { ClientsComponent } from '../../Components/clients/clients.component';
import { TestimonialsComponent } from '../../Components/testimonials/testimonials.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
