import { Component, OnInit } from '@angular/core';
import { ContactAddressComponent } from '../../Components/contact-address/contact-address.component';
import { ContactEnquiryComponent } from '../../Components/contact-enquiry/contact-enquiry.component';
// import { GoogleMapComponent } from '../../Components/google-map/google-map.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
